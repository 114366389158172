.clear:before,
.clear:after {
	content: "";
	display: table;
}

.clear:after {
	clear: both;
}

svg {
	 max-height: 100%;
}

img, svg {
	max-width: 100%;
}

a img {
	display: block;
	vertical-align: middle;
}

html, body {
	position: relative;
	width: 100%; height: 100%;
	margin: 0;
	padding: 0;   
}

.page_contain {
    position: relative;
    width: 100%;
}

.block {
    overflow: hidden;
    width: 100%;
}

.block_outer {
    width: 100%;
    max-width: 112.5em;
    margin: 0 auto;
}

.block_contain {
    position: relative;
    width: 100%;
    padding: 0 1.5em; margin: 0 auto;
    transition: padding .3s ease;
    
    @include fl-break(45em) {
        padding: 0 2.5em;    
    }
    
    @include fl-break(70em) {
        padding: 0 3.5em;    
    }
    
    @include fl-break(75em) {
        padding: 0 8%;    
    }
    
    @include fl-break(90em) {
        padding: 0 14%;    
    }
}

.contain {
	max-width: 112.5em;
	margin: 0 auto;
	
	&.small {
        padding: 0 2em;
        max-width: 60em;
	}
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $dark_silver;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $dark_silver;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: $dark_silver;
}

figure {
	margin: 0 0 4em; padding: 0;
	
	img {
		display: block;
	}
}

figure {
    margin: 0; padding: 0;
    
    img {
        display: block;
        border-radius: .5em;
    }

    figcaption {
        margin-top: 1em;

        p:last-child {
            margin-bottom: 0;
        }
    }
}