.ui_kit {
    .ui_block {
        padding: 7.5em 0;
    }
    
    .type_block {
        margin-bottom: 3em;
        
        &:last-child {
            margin-bottom: 0;
        }
        
        * {
            margin: 0;
        }
        
        .type_label {
            display: block;
            font-family: $headingFont;
            font-size: 1.125em;
            margin-bottom: 1em;
        }
        
        .btn_text {
            font-family: $headingFont;
        }
    }
    
    .colors {
        display: flex;
        list-style-type: none;
        margin: 0 0 1.5em; padding: 0;
        
        &:last-child {
            margin-bottom: 0;
        }
        
        li {
            display: block;
            border-radius: 50%;
            width: 4.5em; height: 4.5em;
            background: $obsidian;
            margin: 0 1.5em 1.5em 0;
            text-indent: -999em;
            
            &.obsidian { background-color: $obsidian; }
            &.black_rhodium { background-color: $black_rhodium; }
            &.dark_silver { background-color: $dark_silver; }
            &.silver { background-color: $silver; }
            &.light_silver { background-color: $light_silver; }
            &.x_light_silver { background-color: $x_light_silver; }
            &.xx_light_silver { background-color: $xx_light_silver; }
            
            &.emerald { background-color: $emerald; }
            &.sapphire { background-color: $sapphire; }
            &.aquamarine { background-color: $aquamarine; }
            &.ruby { background-color: $ruby; }
            &.topaz { background-color: $topaz; }
            
            &.copper { background-color: $copper; }
            &.bronze { background-color: $bronze; }
            &.gold { background-color: $gold; }
            &.rose_gold { background-color: $rose_gold; }
            
            &.red { background-color: $red; }
            &.green { background-color: $green; }
        }
    }
    
    .inputs {
        margin-bottom: 2.5em;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    .btn {
        margin-right: 2em;
        
        &:last-child {
            margin-right: 0;
        }
    }
    
    @include fl-break(40em) {
        .type_block {
            display: flex;
            align-items: baseline;
            
            .type_label {
                text-align: right;
                margin: 0 5% 0 0;
                width: 5.625rem; min-width: 5.625rem;
            }
        }    
    }
}