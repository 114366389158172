.history {
    overflow: hidden;
    padding-bottom: 5em;
    
    .block_outer {
        overflow: hidden;
    }
    
    .timeline {
        position: relative;
        list-style-type: none;
        margin: 4em 0; padding: 4em 0;
        
        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0; bottom: 0;
            width: 150%; left: -25%;
            background: $xx_light_silver;
        }
        
        li {
            position: relative;
            display: block;
            margin-bottom: 4em;
            padding-left: 3em;
            
            &::before {
                content: "";
                position: absolute;
                top: .65em; right: 95%;
                width: 6em;
                border-top: 1px solid $light_silver;
            }
            
            h6 {
                margin-bottom: 1em;
            }
            
            p {
                margin: 0;
            }
            
            &:last-child {
                margin: 0;
            }
        }
    }
    
    @include fl-break(45em) {
        padding-bottom: 0;
        
        .contain {
            padding-bottom: 0;
        }
        
        .timeline {
            margin: 0; padding: 4.5em 10% 10em 0;

            &::before {
                width: 300%; left: auto; right: 0;
            }
            
            li {
                padding: 0;
                
                &::before {
                    right: 105%;
                }
            }
        }
        
        .content .body {
            padding-bottom: 6em;
        }
    }
    
    @include fl-break(78em) {
        .timeline {
            margin-left: -4em; padding-top: 8.5625em;
            
            li {
                padding-left: 4em;
                
                &::before {
                    right: auto; left: 0;
                    width: 2em;
                }
            }
        }
    }
    
    @include fl-break(82em) {
        .timeline {
            margin-left: -6.9375em; padding-bottom: 15em;
            
            li {
                padding-left: 6.9375em;
                
                &::before {
                    width: 4em;
                }
            }
        }
        
        .content .body {
            padding-bottom: 10.9375em;
        }
    }
}

.featured_team_member {
    display: flex;
    flex-direction: column-reverse;
    text-decoration: none;
    color: $white;
    margin-bottom: 4.5em;

    .content {
        padding: 6.5% 5%;
        background: $obsidian;
        
        h3 {
            margin-bottom: .35em;
        }
        
        h6 {
            margin-bottom: 1.5em;
        }

        p {
            color: $white;
        }

        :last-child {
            margin: 0;
        }
    }

    .image {
        img {
            display: block;
        }
    }
    
    @include fl-break(45em) {
        flex-direction: row;

        .content {
            width: 60%; min-width: 60%;
        }
    }
    
    @include fl-break(65em) {
        align-items: center;
        background: $obsidian;
        margin-bottom: 6.5em;

        .content, .image {
            min-width: 50%;
        }
        
        .content {
            padding: 5%;
        }

        .image {
            .image_contain {
                height: 100%;

                img {
                    object-fit: cover;
                    height: 32em;
                }
            }    
        }
    }
}

.team_block {
    margin-top: 7em;
    
    .heading {
        position: relative;
        margin-bottom: 5.6875em;
        
        h2 {
            max-width: 16.96em;
            margin: 0;
        }
    }
    
    .team_list {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        justify-content: center;
        align-items: center;
        width: calc(100% + 3em);
        margin: 0 0 4.5em -1.5em; padding: 0;
        
        li {
            display: block;
            width: 30%; max-width: 13.5em;
            padding: 0 1em; margin-bottom: 2em;
            
            img {
                display: block;
                border-radius: 50%;
                box-shadow: 0 30px 40px 0 rgba(black, .1);
            }
        }
    }
    
    .content {
        p {
            max-width: 26em;
            
            &:nth-child(2n) {
                margin-left: auto;
            }
            
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    
    @include fl-break(40em) {
        .heading {
            padding-right: 6em;
            
            &::after {
                content: "";
                position: absolute;
                top: 0; right: 0;
                width: 4em; height: 4em;
                background: url(/resources/img/icons/sphere_small.min.svg) no-repeat center;
                background-size: contain; 
            }
        }
        
        .team_list {
            li {
                padding: 0 1.5em; margin-bottom: 3.5em;

                img {
                    display: block;
                    border-radius: 50%;
                    box-shadow: 0 30px 40px 0 rgba(black, .1);
                }
            }
        }
    }
    
    @include fl-break(65em) {
        .team_list {            
            li {
                display: block;
                width: 15%; max-width: 13.5em;
            }
        }
    }
    
    @include fl-break(78em) {
        margin-top: 14.5em;
    }
}