.card {
    background: $obsidian;
    
    &.event {
        h2, h4, p { color: $white; } 
        
        &.past {
            background: $white;
            
            h2, h4 {
                color: $obsidian;
            }
            
            p {
                color: $black_rhodium;
            }
        }
        
        .details {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            
            .avatar {
                margin: 0 0 0 auto;
                width: 5em;
                
                img {
                    display: block;
                    border-radius: 50%;
                }
            }
        }
    }
    
    &.press {
        h2, h4, p { color: $white; } 
    }
        
    &.resource {
        background: $white;

        h2, h4 {
            color: $obsidian;
        }

        p {
            color: $black_rhodium;
        }
    }
}
.service_cards {
    .service_card {
        padding: 2em;
        margin-bottom: 2.5em;
        
        h2, h4, p { color: $white; }
        
        &.legal { background: $sapphire; }
        &.business { background: $aquamarine; }
        &.insurance { background: $emerald; }
    }
    
    @include fl-break(35em) {
        .service_card {
            width: 80%;
            margin-bottom: 2.5em;

            &:nth-child(2) {
                margin-left: 20%;
            }

            &:last-child {
                margin-bottom: 0;
                margin-left: 10%;
            }
        }
    }

    @include fl-break(48.75em) {
        .service_card {
            display: flex;
            justify-content: space-between;
            
            .content {
                width: 50%;
                margin-left: 10%;
            }
        }
    }
    
    @include fl-break(55em) {
        .service_card {
            padding: 4%;
        }
    }
    
    @include fl-break(70em) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        
        .service_card {
            display: block;
            width: 31%;
            margin: 0;
            
            &:nth-child(2) {
                margin: 20% 0 0 0;
            }

            &:last-child {
                margin: 10% 0 0 0;
            }
            
            .content {
                width: 100%;
                margin: 0;
            }
        }
    }
}