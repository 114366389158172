.mvp footer {
    .footer_main {
        .footer_nav {
            li {
                margin-bottom: 2em;
            }
        }
    }
    
    @include fl-break(45em) {
        .footer_main .logo img {
            width: 25em;
        }
    }
    
    @include fl-break(65em) {
        .footer_main {
            margin-bottom: 0;
            
            .footer_nav {
                padding-top: 2.4em;
                justify-content: space-around;
            }
        }
        
        .footer_legal {
            margin-top: -1.7em;    
        }
    }
}

.footer_cta {
    margin-top: 6em;
    
    .block_contain {
        padding-top: 7.9375em; padding-bottom: 7.9375em;
        background: url(/resources/img/footer_cta_bg.jpg) no-repeat center;
        background-size: cover;
    }
    
    .cta_contain {
        background: $white;
        width: 90%; max-width: 40.5em;
        padding: 10% 8%;
        
        &.dark {
            background: $obsidian;
            
            h2 {
                color: $white;
            }
        }
        
        &:last-child {
            margin-left: auto;
        }
    }
    
    @include fl-break(55em) {
        margin-top: 12.5em;
        
        .ctas {
            display: flex;
            align-items: center;
            
            .cta_contain {
                width: 50%;
                padding: 5% 3.5%; margin: 0;
                
                .content {
                    transition: transform .8s ease;
                }
                
                &:hover {
                    .content {
                        transform: scale(1.02);
                    }
                }
            }
        }
        
            
    }
}

.content_img_right + .footer_cta {
    @include fl-break(55em) {
        margin-top: 0;
    }
}

.slider_block + .footer_cta {
    margin-top: 1.6875em;
}

footer {
    position: relative;
    padding: 7.5em 0;
    
    .footer_main {
        .logo {
            display: block;
            text-align: center;
            margin-bottom: 3.5em;
            
            img {
                display: block;
                margin: 0 auto;
            }
        }
        
        .footer_nav {
            text-align: center;
            
            ul {
                list-style-type: none;
                margin: 0 0 2em; padding: 0;
                
                li {
                    display: block;
                    margin-bottom: 1em;
                    
                    &.parent a {
                        font-weight: 700;
                    }
                    
                    a {
                        display: block;
                        font-family: $headingFont;
                        color: $black_rhodium;
                        text-decoration: none;
                        
                        &:hover {
                            color: $dark_silver;
                        }
                    }
                }
            }
        }
    }
    
    .footer_legal {
        text-align: center;
        
        p {
            color: $silver;
            margin: 0;
        }
        
        ul {
            list-style-type: none;
            margin: 0; padding: 0;

            li {
                display: block;
                font-size: .875em;  
                line-height: 2.583;
                color: $silver;
                
                a {
                    color: $silver;
                    text-decoration: none;
                }
            }
        }
    }
    
    @include fl-break(30em) {
        .footer_legal {
            ul {
                display: flex;
                justify-content: center;
                align-items: center;

                li {
                    margin: 0 1em;
                }
            }
        }
    }
    
    @include fl-break(65em) {
        .footer_main {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 6.25em;
            
            .footer_nav {
                display: flex;
                justify-content: space-between;
                flex-grow: 1;
                margin-left: 5%;
                padding-top: 1.2em;
                text-align: left;
                
                ul {
                    margin-bottom: 0;
                    
                    li {
                        &.parent {
                            font-size: 1.25em;
                            margin-bottom: 2em;
                        }

                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }
        }
        
        .footer_legal {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            ul {
                justify-content: flex-end;

                li {
                    margin: 0 3.428em 0 0;
                    
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    
    @include fl-break(85em) {
        .footer_main .footer_nav {
            margin-left: 10%;
        }
    }
}