/* Container used for styling the custom select, the buttom class below adds the
 * bg gradient, corners, etc. */
.custom_select {
	position: relative;
	display: block;
    border: 1px solid $x_light_silver;
    background: $xx_light_silver;
    color: $black_rhodium;
    outline: none;
    font-size: 1.125em;
    padding: 1em 2.25em 1em 1em;
}

/* This is the native select, we're making everything but the text invisible so
 * we can see the button styles in the wrapper */
.custom_select select {
	width: 100%;
	margin: 0;
	outline: none;
	box-sizing: border-box;
	font-size: 1rem;
	cursor: pointer;
}

.custom_select select:disabled {
	color: $silver;
	pointer-events: none;
}

/* Custom arrow sits on top of the select - could be an image, SVG, icon font,
 * etc. or the arrow could just baked into the bg image on the select. */
.custom_select::after {
	content: " ";
	position: absolute;
	top: 50%;
	right: 1em;
	z-index: 2;
	pointer-events: none;
	width: 14px;
	height: 21px;
	background: url(/resources/img/icons/drop_arrows.min.svg) no-repeat center;
	background-size: contain;
	transform: translateY(-50%);
}

@supports ( -webkit-appearance: none ) or ( appearance: none ) or ( ( -moz-appearance: none ) and ( mask-type: alpha ) ) {
	
	/* Show custom arrow */
	.custom_select::after {
		display: block;
	}

	/* Remove select styling */
	.custom_select select {
		padding-right: 1.25em; /* Match-01 */
		/* inside @supports so that iOS <= 8 display the native arrow */
		background: none; /* Match-04 */
		/* inside @supports so that Android <= 4.3 display the native arrow */
		border: 1px solid transparent; /* Match-05 */

		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
}

/* Adds Firefox < 35 support */
/* FIREFOX won't let us hide the native select arrow, so we have to make it wider than needed and clip it via overflow on the parent container. The percentage width is a fallback since FF 4+ supports calc() so we can just add a fixed amount of extra width to push the native arrow out of view. We're applying this hack across all FF versions because all the previous hacks were too fragile and complex. You might want to consider not using this hack and using the native select arrow in FF. Note this makes the menus wider than the select button because they display at the specified width and aren't clipped. Targeting hack via http://browserhacks.com/#hack-758bff81c5c32351b02e10480b5ed48e */
/* Show only the native arrow */
@-moz-document url-prefix() {
	/* Warning: this kills the focus outline style */
	.custom_select {
		overflow: hidden;
	}
	.custom_select::after {
		display: block;
	}
	/* Make the native select extra wide so the arrow is clipped. 1.5em seems to be enough to safely clip it */
	.custom_select select {
		overflow: -moz-hidden-unscrollable;
		padding-right: .4em;
		background: none; /* Match-04 */
		border: 1px solid transparent; /* Match-05 */
		/* Firefox < 4 */
		min-width: 6em;
		width: 130%;
		/* Firefox 4-15 */
		min-width: -moz-calc(0em);
		width: -moz-calc(100% + 2.4em);
		/* Firefox 16+ */
		min-width: calc(0em);
		width: calc(100% + 2.4em);
	}

	/* Firefox 35+ that supports hiding the native select can have a proper 100% width, no need for the overflow clip trick */
	@supports ( mask-type: alpha ) {
		.custom_select {
			overflow: visible;
		}
		.custom_select select {
			-moz-appearance: none;
			width: 100%;
			padding-right: 1.25em; /* Match-01 padding-right */
		}
	}
}

/* Firefox focus has odd artifacts around the text, this kills that. See https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-focusring */
.custom_select select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #000;
}

/* IE 10/11+ - This hides native dropdown button arrow so it will have the custom appearance. Targeting media query hack via http://browserhacks.com/#hack-28f493d247a12ab654f6c3637f6978d5 - looking for better ways to achieve this targeting */
/* The second rule removes the odd blue bg color behind the text in the select button in IE 10/11 and sets the text color to match the focus style's - fix via http://stackoverflow.com/questions/17553300/change-ie-background-color-on-unopened-focused-select-box */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
	.custom_select select::-ms-expand {
		display: none;
	}
	.custom_select select:focus::-ms-value {
		background: transparent;
		color: #222; /* Match-02*/
	}
	.custom_select select {
		padding-right: 1.25em; /* Match-01 */
		background: none; /* Match-04 */
		border: 1px solid transparent; /* Match-05 */
	}
	.custom_select::after {
		display: block;
	}
}