.featured_resource {
    a {
        display: flex;
        flex-direction: column-reverse;
        text-decoration: none;
        color: $white;
        
        .content {
            padding: 6.5% 5%;
            background: $obsidian;
            
            p {
                color: $white;
            }
            
            :last-child {
                margin: 0;
            }
        }
        
        .image {
            img {
                display: block;
            }
        }
    }
    
    @include fl-break(45em) {
        a {
            flex-direction: row;
            
            .content {
                width: 60%; min-width: 60%;
            }
        }
    }
    
    @include fl-break(65em) {
        a {
            height: 30em;
            align-items: center;
            background: $obsidian;
            
            .content, .image {
                min-width: 50%;
            }
            
            .image {
                .image_contain {
                    height: 100%;
                    
                    img {
                        object-fit: cover;
                        height: 30em;
                    }
                }    
            }
        }
    }
}