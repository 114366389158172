.pages {
    background: $obsidian;
    
    .hide {
        display: none;
    }
    
    .pages_header {
        margin-bottom: 2em;
        
        .header_contain {
            padding: 2em 0;
            display: flex;
            justify-content: center;
        }
    }
    
    .main {
        h4 {
            color: $white;
        }
    }
}

.pages {
	padding-bottom: 1em;
    
    .main {
        background: $obsidian;
    }
}

.pages_list {
	list-style-type: none;
	margin: 0 0 3.5em; padding: 0;
	
	li a {
		display: flex;
        align-items: center;
		padding: 1em 0;
        text-decoration: none;
		justify-content: space-between;
		border-bottom: 1px solid rgba($white, .16);
		
		&::after {
			content: "View";
			display: block;
			color: $light_silver;
			opacity: 0;
			font-size: .75em;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 3.5px;
			transition: opacity .3s ease;
		}
		
		&:hover {
			&::after {
				opacity: 1;
			}
		}
		
		h5 {
            color: $white;
			margin: 0;
			
			span {
				margin-top: .5em;
				display: block;
				text-transform: none;
				opacity: .5;
				font-weight: 400;
				letter-spacing: 1px;
			}
		}
	}
	
	@include fl-break(35em) {
		li a h5 span {
			display: inline-block;
			margin: 0;
		}
	}
}