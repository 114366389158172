.four_04_content {
    .block_contain {
        position: relative;
        padding-top: 70%; //padding-top: 59.222%;
        background: url(/resources/img/404_bg_2x.jpg) no-repeat center;
        background-size: cover;
        
        .btn {
            position: absolute;
            bottom: 10%; left: 50%;
            transform: translateX(-50%);
        }
    }
    
    @include fl-break(45em) {
        .block_contain {
            padding-top: 59.222%;

            .btn {
                top: 50%; bottom: auto;
            }
        }    
    }
}