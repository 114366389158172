.assessment_hero {
    margin: 4em 0;
    
    .heading {
        position: relative;
        margin-bottom: 5.6875em;
        
        h2 {
            max-width: 16.96em;
            margin: 0;
        }
    }
    
    .content {
        p {
            max-width: 26em;
            
            &:nth-child(2n) {
                margin-left: auto;
            }
            
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    
    @include fl-break(40em) {
        .heading {
            padding-right: 6em;
            
            &::after {
                content: "";
                position: absolute;
                top: 0; right: 0;
                width: 4em; height: 4em;
                background: url(/resources/img/icons/sphere_small.min.svg) no-repeat center;
                background-size: contain; 
            }
        }
    }
    
    @include fl-break(78em) {
        margin: 7em 0;
    }
}