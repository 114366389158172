.service_areas {
    padding-top: 5em;
    
    .contain {
        padding-bottom: 5em;
    }
    
    @include fl-break(45em) {
        padding-top: 10em;
        
        .contain {
            padding-bottom: 12.5em;
        }
    }
}

.services_list {
    list-style-type: none;
    margin: 0; padding: 3.4375em 0 2.4375em;
    
    li {
        position: relative;
        display: block;
        padding: .5em .75em;
        margin-bottom: 2rem;
        color: $white;
        box-shadow: 0 30px 40px 0 rgba(black, .1);
    }
    
    &.business li {
        background: $aquamarine;    
    }
    
    &.insurance li {
        background: $emerald;    
    }
    
    @include fl-break(35em) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        
        li {
            width: 47.5%;
        }
    }
    
    @include fl-break(45em) {        
        li {
            font-size: 1.25em;
        }
    }
    
    @include fl-break(70em) {        
        li {
            width: 31.5%;
        }
    }
    
    @include fl-break(97em) { 
        padding: 4.4375em 0 1.4375em;
        
        li {
            font-size: 1.5em;
            margin-bottom: 3rem;
        }
    }
}