// Fonts
    $headingFont: farnham-display, serif;
    $bodyFont: halyard-display, sans-serif;

// Default Typography Styles

body, input, textarea, button {
	font-family: $bodyFont;
	font-weight: 400;
    color: $obsidian;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, p, ul, ol, blockquote {
	margin-top: 0;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $headingFont;
	font-weight: 400;
}

h1 {
	font-size: 2em;
	line-height: 1.08;
	
	@include fl-break(48.75em) {	
		font-size: 2.5em;
	}
	
	@include fl-break(60em) {
        font-size: 3.5em;
	}
	
	@include fl-break(82em) {
		font-size: 4.5em;
	}
	
	@include fl-break(95em) {		
		font-size: 5.5em;
	}
}

h2 {
	font-size: 1.75em;
    line-height: 1.2;

	@include fl-break(48.75em) {
		font-size: 2.5em;
	}
	
	@include fl-break(70em) {
		font-size: 3.125em;
	}
}

h3 {
	font-size: 1.25em;
	font-weight: 400;
    line-height: 1.19;
	
	@include fl-break(55em) {
		font-size: 1.75em;
	}
	
	@include fl-break(70em) {
		font-size: 2.25em;
	}
}

h4 {
	font-size: 1.25em;
    line-height: 1.2;
	
	@include fl-break(55em) {
		font-size: 1.875em;
	}
}

h5 {
	font-size: 1em;
	font-weight: 700;
    line-height: 1.3;
    
    @include fl-break(35em) {
        font-size: 1.25em;    
    }
}

h6 {
    font-family: $bodyFont;
    font-size: .875em;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: .3em;
    color: $silver;
    
    @include fl-break(35em) {
        font-size: 1em;
    }
}

a {
    color: $obsidian;
    transition: color .3s ease;
    
    &:hover {
        color: $black_rhodium;
    }
}

p {
	font-size: 1em;
	line-height: 1.666;
    color: $black_rhodium;
    
    &.small {
        font-size: .875em;  
        line-height: 2.583;
    }
    
    &.large {
        font-size: 1.25em;
    }
	
	@include fl-break(55em) {
		font-size: 1.125em;
        
        &.large {
            font-size: 1.5em;
        }
	}
}

p, ul, ol {
	margin-bottom: 2em;
}

ul.styled, .content ul {
    list-style-type: square;
    padding: 0 0 0 1.25em;
    
    li {
        p:last-child {
            margin: 0;    
        }
    }
}

q, blockquote {
    margin: 0 0 2.5em;
    
    p {
        quotes: "“" "”" "‘" "’";
        font-family: $headingFont;
        font-style: italic;
        font-size: 2em; 
    }
    
    :last-child {
        margin: 0;
    }
}

.page_content {
    h4 {
        margin-top: 2.5em;
    }
    
    p {
        font-size: 1.25em;
    }
    
    :last-child {
        margin: 0;
    }
    
    blockquote {
        p {
            font-size: 1.5em; 
        }
    }
    
    @include fl-break(55em) {
        p {
            font-size: 1.5em;
        }
        
        blockquote {
            p {
                font-size: 2em; 
            }
        }
    }
}

