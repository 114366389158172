// Select styles

	@import "select";


// Input styles

.btn {
    font-family: $headingFont;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    border: none;
    outline: none;
    color: $white;
    background: $obsidian;
    padding: 1.125em 2em;
    width: 17.5em; max-width: 100%;
    transition: all .3s ease;
    
    &.white {
        background: $white;
        color: $obsidian;
        
        &:hover {
            background: $white;
            color: $black_rhodium;
        }
    }
    
    &:hover {
        background-color: $black_rhodium;
        color: $white;
    }
    
    &:disabled {
        background-color: $light_silver; 
        color: $silver;
    }
}

.arrow_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4em; height: 4em;
    background: url(/resources/img/icons/arrow_btn_bg.min.svg) no-repeat center;
    background-size: contain;
    
    img {
        transition: transform .3s ease;
    }
    
    &.grey {
        background-image: url(/resources/img/icons/arrow_btn_grey_bg.min.svg);
    }
    
    &:hover {
        img {
            transform: translateX(.25em);
        }
    }
}

.input_group {
    position: relative;
    margin-bottom: 1.5em;
    
    label {
        display: block;
        font-size: .875em;
        color: $black_rhodium;
        margin-bottom: .75em;
    }
    
    input[type=text], input[type=tel], input[type=email], input[type=password], textarea {
        position: relative;
        border: 1px solid $x_light_silver;
        background: $xx_light_silver;
        color: $black_rhodium;
        outline: none;
        font-size: 1.125em;
        padding: 1em;
        width: 100%; max-width: 100%;
        transition: all .3s ease;
        
        &:focus {
            border-color: $black_rhodium;
        }
    }
    
    &.success {
        input[type=text], input[type=tel], input[type=email], input[type=password], textarea {
            border-color: $green;
        }
        
        &::after {
            content: " ";
            position: absolute;
            top: 3.3em; right: 1em;
            z-index: 2;
            pointer-events: none;
            width: 18px; height: 11px;
            background: url(/resources/img/icons/input_success.min.svg) no-repeat center;
            background-size: contain;
        }
    }
    
    &.error {
        input[type=text], input[type=tel], input[type=email], input[type=password], textarea {
            border-color: $red;
            color: $red;
        }
        
        .error_message {
            display: block;
            color: $red;
            font-size: .875em;
            margin-top: .5em;
        }
    }
    
    .checkbox {
        input[type="checkbox"] {
            display: none;
        }
        
        label {
            position: relative;
            font-size: 1.125em;
            padding: 1em 0 0 3rem;
            cursor: pointer;
            
            &::before {
                content: "";
                position: absolute;
                top: .85em; left: 0;
                width: 2rem; height: 2rem;
                border: 1px solid $x_light_silver;
                background: $xx_light_silver no-repeat center;
                border-radius: 2px;
                transition: border-color .3s ease;
            }
        }
        
        input[type="checkbox"]:checked + label::before {
            border-color: $black_rhodium;
            background-image: url(/resources/img/icons/checkmark.min.svg);
        }
    }
    
    .radio {
        input[type="radio"] {
            display: none;
        }
        
        label {
            position: relative;
            font-size: 1.125em;
            padding: 1em 0 0 3em;
            cursor: pointer;
            
            &::before {
                content: "";
                position: absolute;
                top: .85em; left: 0;
                width: 2rem; height: 2rem;
                border: 1px solid $x_light_silver;
                background: $xx_light_silver no-repeat center;
                border-radius: 50%;
                transition: border-color .3s ease;
            }
        }
        
        input[type="radio"]:checked + label::before {
            border-color: $black_rhodium;
            background-image: url(/resources/img/icons/radio.min.svg);
        }
    }
}

.input_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    .input_group {
        width: 100%;
    }
    
    @include fl-break(35em) {
        .input_group {
            &.half {
                width: 48.25%;
            }
        }    
    }
    
    @include fl-break(45em) {
        .input_group {
            &.third {
                width: 31%;
            }
            
            &.two_third {
                width: 65.5%;
            }
        }
    }
}