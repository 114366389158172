// Media Query Mixin

$no-mq: false !default;
$no-mq-max: 64em; // Strip out any media queries above 1024px

@mixin fl-break($query) {
	@if $no-mq {
		@if $query < $no-mq-max {
			@content;
		}
	}
	@else {
		@media ( 'min-width:' + $query ) {
			@content;
		}
	}
}

@mixin fl-break-max($query) {
	@if $no-mq {
		@if $query < $no-mq-max {
			@content;
		}
	}
	@else {
		@media ( 'max-width:' + $query ) {
			@content;
		}
	}
}

@mixin fl-break-min-max($query, $query2) {
	@if $no-mq {
		@if $query < $no-mq-max {
			@content;
		}
	}
	@else {
		@media ( 'min-width:' + $query ) AND ( 'max-width:' + $query2 ) {
			@content;
		}
	}
}

@mixin fl-break-height($query) {
	@if $no-mq {
		@if $query < $no-mq-max {
			@content;
		}
	}
	@else {
		@media ( 'min-height:' + $query ) {
			@content;
		}
	}
}

@mixin fl-break-height-max($query) {
	@if $no-mq {
		@if $query < $no-mq-max {
			@content;
		}
	}
	@else {
		@media ( 'max-height:' + $query ) {
			@content;
		}
	}
}
