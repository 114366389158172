// Colors

$white: #FFFFFF;

$obsidian: #121516;
$black_rhodium: #464849;

$dark_silver: #808287;
$silver: #9A9CA0;
$light_silver: #C9C9CC;
$x_light_silver: #E1E3E4;
$xx_light_silver: #F7F8F9;

$emerald: #425F54;
$sapphire: #194066;
$aquamarine: #6597A8;
$ruby: #AF5458;
$topaz: #B96927;

$copper: #AC7049;
$bronze: #BE8478;
$gold: #CC9933;
$rose_gold: #CFA689;

$red: #D13030;
$green: #559B81;