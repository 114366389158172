/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    outline: none;
    
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow {
    position: relative;
    z-index: 10;
    width: 2.5em; height: 2.5em;
    background: $obsidian url(/resources/img/icons/pagination_next.min.svg) no-repeat center;
    border: none;
    outline: none;
    text-indent: -999em;
    transition: background-color .3s ease;
    
    &.slick-prev {
        background-image: url(/resources/img/icons/pagination_prev.min.svg);
    }
    
    &.slick-hidden {
        display: none;
    }
    
    &.slick-disabled {
        pointer-events: none;
        background-color: $x_light_silver;
    }
    
    @include fl-break(45em) {
        width: 4em; height: 4em;   
    }
}

.section_title + .card_slider, .section_title + .testimonial_slider {
    margin-top: -4.5em;
    
    &+ .section_title + .card_slider, &+ .section_title + .testimonial_slider {
        margin-top: -3.75em;
    }
    
    @include fl-break(55em) {
        &+ .section_title + .card_slider, &+ .section_title + .testimonial_slider {
            margin-top: -3.125em;
        }
    }
    
    @include fl-break(70em) {
        &+ .section_title + .card_slider, &+ .section_title + .testimonial_slider {
            margin-top: -4.65em;
        }
    }
}

.card_slider {
    width: calc(100% + 3em);
    margin-left: -1.5em;
    padding: 7.5em 0;
    
    .slick-list {
        overflow: visible;
    }
    
    .slick-slide {
        padding: 0 1.5em;
    }
    
    .slick-arrow {
        position: absolute;
        top: -1.25em; right: 1.5em;

        &.slick-prev {
            right: 5em;
        }
    }
    
    .card {
        background: $obsidian;
        padding: 16%;
        box-shadow: 0 30px 40px 0 rgba(black, .1);
        
        h2, p {
            color: $white;
            
            &:last-child {
                margin: 0;
            }
        }
        
        &.legal {
            background: $sapphire;
        }
        
        &.light {
            background: $white;
            
            h2 {
                color: $obsidian;    
            }
            
            p {
                color: $black_rhodium;
            }
        }
    }
    
    @include fl-break(37.5em) {
        .slick-list div {
            &:nth-child(3n + 2) {
                margin-top: 9.1875em;
            }
            
            &:nth-child(3n + 3) {
                margin-top: 5.0625em;
            }
        }
    }
    
    @include fl-break(45em) {
        padding-bottom: 12.5em;
        
        .slick-arrow {
            top: -2em;

            &.slick-prev {
                right: 7em;
            } 
        }
    }
}

.testimonial_slider {
    width: calc(100% + 3em);
    margin-left: -1.5em;
    padding: 7.5em 0;
    
    .slick-list {
        overflow: visible;
    }
    
    .slick-slide {
        padding: 0 1.5em;
    }
    
    .slick-arrow {
        position: absolute;
        top: -1.25em; right: 1.5em;

        &.slick-prev {
            right: 5em;
        }
    }
    
    .card {
        background: $obsidian;
        padding: 16%;
        box-shadow: 0 30px 40px 0 rgba(black, .1);
        
        h2, p {
            color: $white;
            
            &:last-child {
                margin: 0;
            }
        }
        
        p {
            color: $light_silver;
        }
        
        &.legal {
            background: $sapphire;
        }
        
        &.business {
            background: $aquamarine;
        }
        
        &.insurance {
            background: $emerald;
        }
    }
    
    @include fl-break(45em) {
        padding-bottom: 12.5em;
        
        .card {
            padding: 8%;
        }
        
        .slick-arrow {
            top: -2em;

            &.slick-prev {
                right: 7em;
            } 
        }
    }
}

.image_slider {
    .video_link {
        display: block;
        position: relative;
        
        &::after {
            content: "";
            position: absolute;
            top: 50%; left: 50%;
            width: 4em; height: 4em;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            background: url(/resources/img/icons/play_btn.min.svg) no-repeat center;
            background-size: contain;
            box-shadow: 0 30px 40px 0 rgba(black, .1);
        }
    }
    
    @include fl-break(70em) {
        .video_link {
            &::after {
                top: 45%;
                width: 6em; height: 6em;
            }
        }    
    }
    
    @include fl-break(82em) {
        .video_link {
            &::after {
                top: 42%;
            }
        }    
    }
}

.image_slider_nav {
    position: relative;
    z-index: 10;
    height: 1.25em;
    
    .slick-arrow {
        position: absolute;
        bottom: 0; right: 2em;

        &.slick-prev {
            right: 5.5em;
        }
    }
    
    @include fl-break(45em) {
        height: 2em;
        
        .slick-arrow {
            &.slick-prev {
                right: 7.5em;
            } 
        }
    }
    
    @include fl-break(82em) {
        height: 0;
        
        .slick-arrow {
            bottom: 1em; right: 3em;
            
            &.slick-prev {
                right: 8.5em;
            }
        }
    }
}