.header_block {
    position: relative;
    z-index: 100;
}

header.main {
    position: relative;
    background: $white;
    z-index: 100;
    
    .logo {
        margin-right: 2em;
    }
    
    .logo, .nav_trigger {
        position: relative;
        z-index: 2;
    }
    
    .contain {
        padding: 2em 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0; left: -1.5em;
            width: calc(100% + 3em); height: 6.9375em;
            background: $white;
        }
    }
    
    @include fl-break(30em) {
        .contain::before {
            height: 7em; 
        }
    }
    
    @include fl-break(40em) {
        .contain {
            padding: 4.25em 0;
            
            &::before {
                height: 10em
            }
        }
    }
    
    @include fl-break(45em) {
        .contain::before {
            left: -2.5em;
            width: calc(100% + 5em);
        }
    }
    
    @include fl-break(63em) {
        .nav_trigger {
            display: none;
        }
        
        .contain {
            padding: 6.25em 0 4.1875em;
            
            &::before {
                display: none;
            }
        }
    }
}

.header_nav {
    position: absolute;
    background: rgba($white, .9);
    border-bottom: 1px solid $silver;
    top: 0; left: -1.5em;
    width: calc(100% + 3em);
    padding-top: 6.9375em;
    padding-bottom: .5em;
    opacity: 0;
    transform: translateY(-100%);
    transition: all .6s ease;
    
    .nav--on & {
        opacity: 1;
        transform: none;
    }
    
    ul {
        list-style-type: none;
        margin: 0; padding: 0;
        text-align: center;
        
        li {
            display: block;
            
            a, .drop_trigger {
                display: block;
                font-family: $headingFont;
                color: $black_rhodium;
                text-decoration: none;
                padding: 1em 0;
                
                &:hover {
                    color: $dark_silver;    
                }
            }
            
            .drop_trigger {
                display: none;
                
                &:hover {
                    color: $black_rhodium;  
                }
            }
        }
    }
    
    @include fl-break(30em) {
        padding-top: 7.6875em;    
    }
    
    @include fl-break(40em) {
        padding-top: 12.1875em;
    }
    
    @include fl-break(45em) {
        left: -2.5em;
        width: calc(100% + 5em);    
    }
    
    @include fl-break(63em) {
        position: inherit;
        top: auto; left: auto;
        background: none;
        border: none;
        padding: 1em 0 0;
        opacity: 1;
        transform: none;
        width: auto;
        flex-grow: 1;
        
        &> ul {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            
            li {
                position: relative;
                margin-left: 1.5em;
                
                &:first-child {
                    margin-left: 0;
                }
                
                a, .drop_trigger {
                    padding-bottom: 1.5em;
                }
                
                .drop_trigger {
                    display: block;
                }
                
                .dropdown {
                    position: absolute;
                    top: 100%; left: 50%;
                    background: $obsidian;
                    padding: 1.5em;
                    opacity: 0;
                    pointer-events: none;
                    transform: translate(-50%, -.5em);
                    transition: all .3s ease;
                    
                    &::before {
                        content: "";
                        position: absolute;
                        top: .25em; left: 50%;
                        width: 1.5em; height: 1.5em;
                        border: none;
                        background: $obsidian;
                        transform: rotate(45deg) translateX(-75%);    
                    }
                    
                    li {
                        display: block;
                        text-align: center;
                        margin: 0;
                        
                        a {
                            color: $white;
                            opacity: 1;
                            padding: 0;
                            margin-bottom: .5em;
                            white-space: nowrap;
                        }
                        
                        &:last-child a {
                            margin: 0;
                        }
                    }
                }
                
                &:hover .dropdown {
                    pointer-events: inherit;
                    opacity: 1;
                    transform: translate(-50%, 0);
                }
            }
        }
    }
    
    @include fl-break(70em) {
        &> ul li {
            margin-left: 2em;
        }
    }
    
    @include fl-break(93em) {
        &> ul li {
            margin-left: 3em;
        }
    }
}