/* ==========================================================================
Remodal's necessary styles
========================================================================== */

/* Hide scroll bar */

html.remodal-is-locked {
	overflow: hidden;
	touch-action: none;
}

/* Anti FOUC */

.remodal, [data-remodal-id] {
	display: none;
}

/* Necessary styles of the overlay */

.remodal-overlay {
	position: fixed;
	z-index: 9999;
	top: -99em;
	right: -99em;
	bottom: -99em;
	left: -99em;
	display: none;
}

/* Necessary styles of the wrapper */

.remodal-wrapper {
	position: fixed;
	z-index: 10000;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: none;
	overflow: auto;
	text-align: center;
	-webkit-overflow-scrolling: touch;
}

/* Fix iPad, iPhone glitches */

.remodal-overlay, .remodal-wrapper {
	backface-visibility: hidden;
}

/* Necessary styles of the modal dialog */

.remodal {
	outline: none;
	text-size-adjust: 100%;
}

.remodal-is-initialized {
	/* Disable Anti-FOUC */
	display: inline-block;
}


/* ==========================================================================
Remodal's default mobile first theme
========================================================================== */

/* Default theme styles of the overlay */

.remodal-overlay {
	background: rgba($obsidian, 0.8);
}

.remodal-overlay.remodal-is-opening, .remodal-overlay.remodal-is-closing {
	animation-duration: 0.3s;
	animation-fill-mode: forwards;
}

.remodal-overlay.remodal-is-opening {
	animation-name: remodal-overlay-opening-keyframes;
}

.remodal-overlay.remodal-is-closing {
	animation-name: remodal-overlay-closing-keyframes;
}

/* Default theme styles of the wrapper */

.remodal-wrapper {
	padding: 3.5em 1em 1em;
    
    @include fl-break(60em) {
        padding: 4em 4em 1em;
    }
}

/* Default theme styles of the modal dialog */

.remodal {
    position: relative;
    max-width: 53em;
    padding: 3em 2em 1.25em;
    background: $white;
    text-align: left;
    
    &.video {
        padding: 0;
        width: 90%;
        max-width: 80em;
        
        .video_content {
            position: relative;
            padding-top: 56.25%;
        }
        
        iframe {
            position: absolute;
            top: 0; left: 0;
            display: block;
            width: 100%; height: 100%;
        }
    }
    
    .heading {
        display: flex;
        align-items: center;
        margin-bottom: 2.8125em;
        
        h4 {
            margin-bottom: .35em;
        }
        
        h6 {
            margin-bottom: 1.5em;
        }
        
        .avatar {
            display: block;
            width: 5.5em;
            margin-right: 1.5em;
            
            img {
                display: block;
                border-radius: 50%;
                box-shadow: 0 30px 40px 0 rgba(black, .1);
            }
        }
        
        .social_links {
            display: flex;
            align-items: center;
            list-style-type: none;
            margin: 0; padding: 0;
            
            li {
                display: block;
                margin-right: 1em;
                
                &:last-child {
                    margin-right: 0;
                }
                
                img {
                    display: block;
                }
            }
        }
    }
    
    .content {
        :last-child {
            margin-bottom: 0;
        }
    }
    
    @include fl-break(35em) {
        padding: 3.5em 3.5em 2em;   
        
        .heading {
            .avatar {
                width: 8em;
                margin-right: 2.25em;
            }
        }
    }
    
    @include fl-break(65em) {
        padding: 3.5em 7em; 
        
        .heading {
            .avatar {
                width: 11em;
                margin-right: 2.9375em;
            }

            .social_links li {
                margin-right: 2em;
            }
        }
    }
}



.remodal.remodal-is-opening, .remodal.remodal-is-closing {
	animation-duration: 0.3s;
	animation-fill-mode: forwards;
}

.remodal.remodal-is-opening {
	animation-name: remodal-opening-keyframes;
}

.remodal.remodal-is-closing {
	animation-name: remodal-closing-keyframes;
}

/* Close button */

.remodal-close {
	position: absolute;
	z-index: 1;
	top: 1.5rem; right: 1.5rem;
	display: block;
	overflow: visible;
	width: .875rem;
	height: .875rem;
	margin: 0;
	padding: 0;
	opacity: 1;
	cursor: pointer;
	border: none;
	outline: none;
	background: url(/resources/img/icons/modal_close.min.svg) no-repeat center;
	background-size: contain;
    
    @include fl-break(70em) {
        top: 2em; right: 2em;
    }
}

/* Keyframes
========================================================================== */

@keyframes remodal-opening-keyframes {
	from {
		transform: scale(1.05);
		opacity: 0;
	}
	to {
		transform: none;
		opacity: 1;
	}
}

@keyframes remodal-closing-keyframes {
	from {
		transform: scale(1);
		opacity: 1;
	}
	to {
		transform: scale(0.95);
		opacity: 0;
	}
}

@keyframes remodal-overlay-opening-keyframes {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes remodal-overlay-closing-keyframes {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
