.fade_in {
    opacity: 0;
    transform: translateY(2em);
    
    .no-js & {
        opacity: 1;
        transform: translateY(0);
        transition: none;
    }
}

.fade_in.visible { 
    opacity: 1;
    transform: translateY(0);
    transition: opacity .8s ease, transform 1s ease;
}