.block {
    &.hero_text {
        padding-top: 4em; 
        margin-bottom: 3.5em;
        
        h1 {
            margin: 0;
        }
    }
    
    &.hero_image {
        margin: 3.5em 0;
        
        &.event {
            .event_details {
                margin-bottom: 4em;
            }
            
            .video_link {
                display: block;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    top: 50%; left: 50%;
                    width: 4em; height: 4em;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    background: url(/resources/img/icons/play_btn.min.svg) no-repeat center;
                    background-size: contain;
                    box-shadow: 0 30px 40px 0 rgba(black, .1);
                }
            }
        }
    }
    
    &.extend_left_border, &.left_border {
        padding-top: 8em;
        
        .contain {
            position: relative;
            
            &::before {
                content: "";
                position: absolute;
                bottom: calc(100% + 5em); left: 0;
                height: 3.25em;
                border-left: 1px solid $light_silver;
            }
        }
    }
    
    &.left_border_content {
        padding-top: 5em;
        
        .contain {
            position: relative;
            
            &::before {
                content: "";
                position: absolute;
                bottom: calc(100% + 2em); left: 0;
                height: 3.25em;
                border-left: 1px solid $light_silver;
            }
        }
        
        .page_content {
            max-width: 53em;
        }
        
        .speaker {
            margin: 4.5em 0 0;
            padding-bottom: 4.5em;
            
            h6 {
                margin-bottom: 1.25em;
            }
            
            .speaker_details {
                display: flex;
                align-items: center;
                border-top: 1px solid $light_silver;
                border-bottom: 1px solid $light_silver;
                padding: 2em 0;

                h4 {
                    margin: 0 0 .35em;
                }

                h6 {
                    margin-bottom: 1.5em;
                }

                .avatar {
                    display: block;
                    width: 5.5em;
                    margin-right: 1.5em;

                    img {
                        display: block;
                        border-radius: 50%;
                        box-shadow: 0 30px 40px 0 rgba(black, .1);
                    }
                }

                .social_links {
                    display: flex;
                    align-items: center;
                    list-style-type: none;
                    margin: 0; padding: 0;

                    li {
                        display: block;
                        margin-right: 1em;

                        &:last-child {
                            margin-right: 0;
                        }

                        img {
                            display: block;
                        }
                    }
                }
            }
        }
        
        .downloads {
            margin: 4.5em 0 0;
            padding-bottom: 4.5em;
            
            h6 {
                margin-bottom: 1.25em;
            }
            
            .downloads_list {
                list-style-type: none;
                margin: 0; padding: 0;
                
                li {
                    display: block;
                    padding: 1em 0;
                    border-bottom: 1px solid $light_silver;
                    
                    &:first-child {
                        border-top: 1px solid $light_silver;    
                    }
                    
                    h5 {
                        margin-bottom: .25em;
                    }
                }
            }
        }
    }
    
    &.grey_right {
        .block_contain::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0; right: 0; bottom: 0;
            width: 40%;
            background: $xx_light_silver;
        }
    }
    
    &.slider_block {
        padding-top: 5em;
        
        .block_contain::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0; right: 0; bottom: 0;
            width: 100%;
            background: $xx_light_silver;
        }
        
        .section_title {
            height: 4.5em;
            padding-top: 1.5em;
            max-width: 50%;
            
            h4 {
                margin: 0;
            }
        }
    }
    
    &.services_listing {
        padding-top: 5em;    
    }
    
    &.full_image {
        img {
            display: block;
            width: 100%;
        }
    }
    
    &.featured_resource {
        margin: 3.5em 0;
    }
    
    &.head_l_body_r {
        .heading {            
            .heading_graphic {
                display: none;
                margin-bottom: .25em;
            }
            
            .btn {
                margin-bottom: 4em;
            }
        } 
        
        .body {
            :last-child {
                margin-bottom: 0;
            }
        }
    }
    
    &.head_t_body_b {
        padding-top: 4em; 
        
        .heading {
            margin-bottom: 3.5em;
            
            h1 {
                max-width: 9.636363636em;
                margin: 0;
            }
            
            .btn {
                margin: 2.5em 0 .5em;
            }
        }
        
        .body .body_graphic {
            display: none;
        }
    }
    
    &.content_split {
        padding-top: 4em; 
        
        .content {
            .heading {
                h3 {
                    max-width: 12.444444444em;
                }
            }
            
            .main {
                a.btn {
                    margin-top: 3.5em;
                }
            }
        }
    }
    
    &.content_img_right {
        .contain {
            padding: 5em 0 48%;

            .content {
                position: relative;
                z-index: 1;

                h1 {
                    max-width: 8.204em;
                }

                h2 {
                    position: relative;
                    max-width: 12.48em;
                    
                    &:last-child {
                        margin: 0;

                        &::after {
                            content: "";
                            position: absolute;
                            top: calc(100% + 3.3125rem); left: 0;
                            height: 30em;
                            border-left: 1px solid $light_silver;
                        }
                    }
                }
                
                p {
                    margin-bottom: 2.5em;
                    
                    a {
                        color: $black_rhodium;
                        text-decoration: none;
                    }
                    
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                
                .btn:last-child {
                    &::after {
                        content: "";
                        position: absolute;
                        top: calc(100% + 3.3125rem); left: 0;
                        height: 30em;
                        border-left: 1px solid $light_silver;
                    }    
                }
                
                .actions {
                    .btn {
                        display: block;
                        margin-right: 1.5em;
                        margin-bottom: 1.5em;

                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }

            .img_contain {
                position: absolute;
                bottom: 0; right: 0;
                width: 70%;

                img {
                    display: block;
                }

                .floater {
                    position: absolute;
                    width: 55.636%;
                    top: 50%; left: 50%;
                    transform: translate(-50%, -50%);

                    img {
                        border-radius: 50%;
                        box-shadow: 0 30px 40px 0 rgba(black, .1);
                    }
                }
            }
        }
        
        &.no_border {
            .contain {
                .content {
                    h2:last-child::after {
                        display: none;
                    }
                    .btn:last-child::after {
                        display: none;
                    }
                } 
            }
        }
    }
    
    @include fl-break(35em) {
        &.left_border_content {
            .speaker .speaker_details {
                .avatar {
                    width: 8em;
                    margin-right: 2.25em;
                }    
            }
            
            .downloads {            
                .downloads_list {
                    li {
                        display: flex;
                        justify-content: space-between;

                        h5 {
                            margin: 0;
                        }
                        
                        a {
                            white-space: nowrap;
                            margin-left: 2em;
                        }
                    }
                }
            }
        }
    }
    
    @include fl-break(45em) {
        &.extend_left_border, &.left_border {
            padding-top: 13.5em;
            
            .contain::before {
                height: 7em;
                bottom: calc(100% + 7em);
            }
        } 
        
        &.slider_block {
            padding-top: 10em;     
        }
        
        &.services_listing {
            padding-top: 10em;    
        }
        
        &.head_l_body_r {
            .content {
                display: flex;
                justify-content: space-between;
                
                .body {
                    width: 48%; min-width: 48%;
                    margin-left: 10%;
                }
            }
            
            .heading {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                
                h3 {
                    max-width: 12.444em;
                }
                
                .btn {
                    margin: 3em 0 auto;
                    
                    &.bottom {
                        margin-bottom: 0;
                    }
                }
                
                .heading_graphic {
                    display: block;
                }
            }    
        }
        
        &.head_t_body_b {
            padding-top: 7em; 

            .heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5em;
                
                h1 {
                    flex-grow: 1;
                }
                
                .btn {
                    margin: .5em 0 0 5%;
                }
            }
            
            .body {
                display: flex;
                justify-content: space-between;
                border-left: 1px solid $light_silver;
                padding-left: 17%;
                
                .main {
                    width: 62.651%;
                }
                
                .body_graphic {
                    display: block;
                    width: 17.5em;
                    margin-left: 5%;
                    text-align: center;
                    
                    &.large {
                        position: relative;
                        
                        img {
                            margin-top: -4em;
                            max-width: none; width: 33em;
                        }
                    }
                }
            }
        }
        
        &.content_split {
            padding-top: 7em;
            
            .content {
                display: flex;
                justify-content: space-between;
                
                .heading, .main {
                    width: 48%;
                }
                
                .heading h3 {
                    position: relative;
                    margin: 0;
                    
                    &::after {
                        content: "";
                        position: absolute;
                        top: calc(100% + 4.125rem); left: 0;
                        height: 100vh;
                        border-right: 1px solid $light_silver;
                    }
                }
            }
        }
        
        &.content_img_right {
            .contain .content {
                .actions {
                    display: flex;
                    
                    .btn {
                        display: block;
                        margin-bottom: 0;
                    }
                } 
            }
        }
    }
    
    @include fl-break(65em) {
        &.left_border_content {
            .speaker .speaker_details {
                .avatar {
                    width: 11em;
                    margin-right: 2.9375em;
                }

                .social_links li {
                    margin-right: 2em;
                }    
            }
        }
    }
    
    @include fl-break(70em) {
        &.hero_text {
            margin-bottom: 5.5em;
        }
        
        &.hero_image {
            margin: 5.5em 0;
            
            &.event {
                .block_contain {
                    padding-right: 0;
                }
                
                .contain {
                    display: flex;
                    align-items: center;
                }
                
                .event_details {
                    margin-bottom: 0;
                    width: 28.75em;
                    
                    p:last-child {
                        margin: 0;
                    }
                }
                
                img {
                    flex-grow: 1;
                }
                
                .video_link {
                    &::after {
                        width: 6em; height: 6em;
                    }
                }
            }
        }
        
        &.grey_right, &.slider_block {
            .block_contain::before {
                width: 50%;
            }
        }
        
        &.slider_block {
            .section_title {
                padding-top: 0;
            }
        }
        
        &.head_t_body_b {
            .body {
                .body_graphic {                    
                    &.large {
                        img {
                            position: absolute;
                            margin: 0;
                            bottom: 4em; left: 0;
                            max-width: none; width: 53em;
                        }
                    }
                }
            }
        }

        &.featured_resource {
            margin: 5.5em 0;
        }
    }
    
    @include fl-break(78em) {
        &.hero_text {
            padding-top: 8.5em;
        }
        
        &.left_border {
            padding-top: 0;
            
            .contain {
                padding: 10em 0 2em 4em;
                border-left: 1px solid $light_silver;
                
                &::before {
                    display: none;
                }
            }
            
            .content .body {
                width: 52.55%;
            }
            
            &.no_bottom_pad {
                .contain {
                    padding-bottom: 0;
                }
            }
        }
        
        &.left_border_content {
            padding-top: 0;
            
            .contain {
                padding: 0 0 0 17.43%;
                border-left: 1px solid $light_silver;
                
                &::before {
                    display: none;
                }
            }
        }
        
        &.content_img_right {
            padding-top: 11.375em;
            
            &.hero {
                padding-top: 0;
            }
            
            .contain {
                padding: 10em 0 19.290%;

                .img_contain {
                    bottom: auto; top: 0; right: auto; left: 38.889%;
                    width: 61.111%;
                }
            }
        }
    }
    
    @include fl-break(82em) {
        &.left_border .contain {
            padding-left: 6.9375em;
        }
	}
}

.full_image + .head_l_body_r {
    .contain {
        padding-top: 4.5em;
    }
    
    @include fl-break(70em) {
        margin-top: -6em;
        
        .block_contain {
            position: relative;
            z-index: 1;
            
            .contain {
                position: relative;
                padding-right: 4em;

                &::before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0; right: 0;
                    width: 200%; height: 100%;
                    background: $white;
                }
            }
        }
    }
    
    @include fl-break(82em) {
        margin-top: -8em;
        
        .block_contain {            
            .contain {
                padding-top: 8.75em;
                padding-right: 6.9375em;
            }
        }    
    }
}